import { ConfigElement } from '@/models/ConfigElement'
import { ConfigStep } from '@/models/ConfigStep'
import { ConfigChoice } from '@/models/ConfigChoice'
import {Type} from "class-transformer";

export class Configurateur {
  @Type(() => ConfigElement)
  configs: ConfigElement[] = []

  @Type(() => ConfigStep)
  steps: ConfigStep[] = [] // global steps

  @Type(() => ConfigChoice)
  items: ConfigChoice[] = []

  public noConfig = 0
  public getSteps (noConfig: number) {
    return this.steps
  }

  public getItemsByVariable (varname: string): ConfigChoice[] {
    return this.getItemsByVariables([varname])
  }

  public getItemsByVariables (varname: string[]): ConfigChoice[] {
    const returns = []
    if (this.items) {
      for (const item of this.items) {
        if (varname.includes(item.var)) {
          returns.push(item)
        }
      }
    }
    return returns
  }
}
