

import { Component, Vue } from 'vue-property-decorator'
import Icon from 'vue-awesome'
import { namespace } from 'vuex-class'

const ConfigurateurModule = namespace('configurateur')

@Component({
  components: {
    Icon
  }
})
export default class App extends Vue {
  @ConfigurateurModule.Getter public isLoad: boolean | undefined
}
