

import { Component, Vue } from 'vue-property-decorator'
import { Configurateur } from '@/models/Configurateur'
import { Volet } from '@/models/Volet'
import { namespace } from 'vuex-class'
import Icon from 'vue-awesome'
import { ConfigStep } from '@/models/ConfigStep'
import Widget from './Widget.vue'
import { ConfigElement } from '@/models/ConfigElement'

const ConfigurateurModule = namespace('configurateur')

@Component({
  components: {
    Icon,
    Widget
  }
})
export default class Steps extends Vue {
  public lastVolet = false
  public code = ""
  @ConfigurateurModule.Getter public configs!: Configurateur
  @ConfigurateurModule.Getter public prixTtcCode!: string
  @ConfigurateurModule.Getter public volet!: Volet
  @ConfigurateurModule.Getter public src!: string
  @ConfigurateurModule.Getter public isLoad!: boolean
  @ConfigurateurModule.Getter public locale!: string
  @ConfigurateurModule.Getter public steps!: ConfigStep[]
  addVolet () {
    this.$store.commit('addVolet', this.$store.state.configs.base)
  }

  addVoletWithSameImplantation () {
    /*const v = JSON.parse(JSON.stringify(this.$store.state.configs.base))
    v.base = this.volet.base
    v.contour = this.volet.contour
    v.implantation = this.volet.implantation
    v.step = 2
    this.$store.commit('addVolet', v)*/
  }

  openable (index: number): boolean {

    if (index == 0) return true
    //if (step.global) return true
    const previousStep = this.steps[index - 1]
    if (previousStep.final) return true
    for (const item of previousStep.items) {
      if (!item.no_validate) {
        if (!this.volet.getValue(item.var)) {  // variable non settée
          return false
        }
      }
    }
    return true
  }

  isAlreadyChoice (index: number): boolean {
    const step = this.steps[index]
    if (step.global) {
      return true
    }
    if (step.final) {
      return (this.volet.isLast)
    }
    for (const item of step.items) {
      if (!item.no_validate) {
        if (!this.volet.getValue(item.var)) {  // variable non settée
          return false
        }
      }
    }
    return true
  }

  open (index: number) {

    if (this.openable(index)) {
      this.volet.step_index = index
      const top = document.getElementById('global')!.offsetTop // Getting Y of target element
      window.scrollTo(0, top + index * 40)
    }
  }

  finir (index: number) {
    this.$store.commit('setIsLast', true)
    this.lastVolet = true
    this.open(index)
  }

}
