

import { Configurateur } from '@/models/Configurateur'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { ConfigItem } from '@/models/ConfigItem'
import { Volet } from '@/models/Volet'
import { namespace } from 'vuex-class'
import { Projet } from '@/models/Projet'
import { ConfigElement } from '@/models/ConfigElement'
import { Tranche } from '@/models/Tranche'
import Icon from "vue-awesome";
import VisuelVolet from '@/components/Common/VisuelVolet.vue'
import ModalSave from './Common/ModalSave.vue'
import { ConfigChoice } from '@/models/ConfigChoice'
import { ConfigPayload } from '@/store/configurateur.module'
import { getCookie } from '@/util/CookieService'

const ConfigurateurModule = namespace('configurateur')

@Component({
  components: {
    VisuelVolet,
    ModalSave,
    Icon
  }
})
export default class Panier extends Vue {
  private showModalSave = false
  @Prop() public locale: string | undefined
  @Prop() public code: string | undefined

  @ConfigurateurModule.Getter public configs!: Configurateur
  @ConfigurateurModule.Getter public prixTtcCode!: string
  @ConfigurateurModule.Getter public src!: string
  @ConfigurateurModule.Getter public isLoad!: boolean
  @ConfigurateurModule.Getter public projet!: Projet
  @ConfigurateurModule.Getter public tranche!: Tranche

  mounted() {
    if (this.code && this.locale) {
      this.$i18n.locale = this.locale
      this.$store.commit('configurateur/setLocale', this.locale)
      const payload = new ConfigPayload()
      payload.locale = this.locale
      payload.projetCode = this.code
      this.$store.dispatch('configurateur/fetchConfig', payload)
    }
  }

  closePopupSave () {
    this.showModalSave = false
  }

  openPopupSave () {
    this.showModalSave = true
  }

  incQte (volet: Volet, inc: number) {
    volet.qte += inc
    this.$store.commit('configurateur/setTrancheByQte')
    this.$store.commit('configurateur/updatePrixVolet');
    this.$store.dispatch('configurateur/save');
  }

  incGlobalQte(choice: ConfigChoice, inc: number) {
    this.projet.incQte(choice, inc)
    this.projet.calcPrix(this.tranche)
    console.log(this.projet.totalOption)
    this.$store.commit('configurateur/updatePrixVolet');
  }

  stepsByVolet (volet: Volet) {
    return this.configs.getSteps(volet.noconfig)
  }

  isMobile () {
    return (window.innerWidth <= 768)
  }

  /*
  stepLastOnly () {
    const r = []
    for (const k in this.configs.steps) {
      if (this.configs.steps[k].last_only) {
        r.push(this.configs.steps[k])
      }
    }
    return r
  }

  isAllFini () {
    for (const k in this.$store.state.projet.volets) {
      if (!this.$store.state.projet.volets[k].fini) return false
    }
    return true
  }
  */
  send(email: string) {
    this.projet.email = email
    this.$store.dispatch('configurateur/send')
    this.showModalSave = false
  }

  removeOverlay () {
    this.showModalSave = false
  }

  isShowContactInstall (): boolean|string {
    /* if (this.configsfile.showContact != undefined) {
       if (this.configsfile.showContact[this.locale] != undefined) {
         return this.configsfile.showContact[this.locale]
       }
       return this.configsfile.showContact
     }*/
    return true
  }

  contactInstall() {
    const val = this.isShowContactInstall();

    if (val === true) {
      return '/trouver-revendeur-installateur';
    } else if (val) {
      return val;
    } else {
      return null;
    }
  }

  editVolet (volet: Volet) {
    volet.step_index=0
    this.$store.commit('configurateur/setVolet', volet)
    this.$router.push({ name: 'config'})
  }
  deleteVolet (volet: Volet) {
    this.$store.commit('configurateur/deleteVolet', volet)
  }

  reset() {
    this.$store.commit('configurateur/initProject')
    this.$router.push({ name: 'config'})
  }
}
