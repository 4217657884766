import { Tranche } from '@/models/Tranche'
import { Type } from 'class-transformer'

export class Prix {
  id= 0
  code: string = ""
  @Type(() => Number)
  prix: number = 0
  @Type(() => Number)

  prix2: number = 0
  @Type(() => Number)
  prix3: number = 0

  constructor (data: any) {
    Object.assign(this, data)
  }
  prixForTranche (tranche: Tranche): number {
    console.log("prix tranche", tranche.field)
    if (tranche.field == "prix") {
      return this.prix
    }
    if (tranche.field == "prix2") {
      return this.prix2
    }
    if (tranche.field == "prix3") {
      return this.prix3
    }
    return 0
  }
}
