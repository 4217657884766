import { Widget } from '@/models/Widget'
import { Value } from '@/models/Value'
import {Type} from "class-transformer";

export class ConfigItem {
  id = 0
  code = ""
  label =""
  var = ''
  value : Value|null = null
  intro: null | string = null
  no_validate = false
  visuel: null | string = null
  hiddenRecap = false

  @Type(() => Widget)
  widget: Widget | null = null
  action: string | null = null
  img: null | string = null
  aideHTML: null | string = null
  html: null | string = null
  footer: null | string = null
  cssClass: string | null = null
  type: string | null = null
  translations: string[] = []
  locale = 'fr'
  subintro = ''
  aide = ''
  global = false

  public data = []
  public hasVisuel () {
    return (this.visuel != 'null')
  }

}
