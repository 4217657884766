export class Base {
  fini = false;
  baseVR?: any[];
  implantationVR?: any[];
  teinteFondVR?: any[];
  teinteFenetreVR?: any[];
  tablier?: any[];
  teinteTablier?: any[];
  teinteCadre?: any[];
  motorisation?: any[];
  domotique: any[] = [];
  prix: number = 0;
  prixU: number = 0;
  prixV: number = 0;
  prixUV: number = 0;
  qte: number = 0;
  baseTarifaire ="";
  name = "";
  baseVB?: any[];
  implantationVB?: any[];
  teinteFondVB?: any[];
  teinteFenetreVB?: any[];
  teinteCapot?: any[];
  teinteAccessoire?: any[];
  baseZip?: any[];
  implantationZip?: any[];
  teinteFondZip?: any[];
  teinteFenetreZip?: any[];
}
