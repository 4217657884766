import { Base } from '@/models/Base'
import { ConfigStep } from '@/models/ConfigStep'
import { Type } from 'class-transformer'
import { Volet } from '@/models/Volet'

export class ConfigElement {
  name: string | undefined
  code = ''
  showMessageTranchee = false
  varPriceBase: null | string = ''
  choice_name = ''
  base_visuel = ''
  default_name_elm = ''
  sub_name_elm = ''
  css_class = ''

  @Type(() => Base)
  base: Base | null = null
  gride_price: any[] = []
  translations: string[] = []
  locale = 'fr'
  class = ''

  @Type(() => ConfigStep)
  steps: ConfigStep[] = []

  newVolet (no: number = 1, noConfig: number): Volet {
    const volet = new Volet()
    volet.base_visuel = this.base_visuel
    volet.name = this.default_name_elm + no
    volet.qte = 1
    volet.noconfig = noConfig
    return volet
  }
}

