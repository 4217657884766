import Vue from 'vue'
import Vuex from "vuex"
import ConfigurateurStore from '@/store/configurateur.module'

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    "configurateur": ConfigurateurStore,
  }
})
