import { ConfigItem } from '@/models/ConfigItem'
import { Value } from '@/models/Value'
import { ConfigChoice } from '@/models/ConfigChoice'
import { Tranche } from '@/models/Tranche'
import { Type } from 'class-transformer'

export class Volet {
  public noconfig = 0
  public step_index = 0
  public isLast = false
  public name = ''

  @Type(() => Value)
  public values: Value[] = []
  public fini = false
  public qte = 1
  prixBase: number = 0 // prix de base du volet sans option ( issu de la table des prix )
  prixU: number = 0 // Prix unitaire d'un volet avec options choisies
  base_visuel: string = ''

  constructor (data?: any) {
    if (data) {
      Object.assign(this, data)
    }
  }

  public calcPrix (tranche: Tranche) {

    let prix = Number(this.prixBase)

    // Ajout des options
    for (const value of this.values) {
      const choice = value.choice
      if (choice) {
        const prix_option = choice.prixForTranche(tranche)
        value.prixU = prix_option

        if (prix_option) {
          prix += Number(prix_option)
        }
      }
    }
    this.prixU = prix
  }

  prixTotal (): number {
    return this.qte * this.prixU
  }

  valueForVar (varname: string): Value[] {
    return this.values.filter(v => {
      return v.var == varname
    })
  }

  containValue (configItem: ConfigItem, choice: ConfigChoice): boolean {
    return !!this.values.find(v => {
      return v.var == configItem.var && v.code == choice.code
    })
  }

  resetValue (configItem: ConfigItem) {
    this.resetVariable(configItem.var)
  }

  resetVariable (variable: string) {
    this.values = this.values.filter(obj => {
      return obj.var !== variable
    })
  }

  setValue (value: Value) {
    const existingIndex = this.values.findIndex((v) => v.var === value.var)
    if (existingIndex !== -1) {
      this.values[existingIndex] = value
    } else {
      this.values.push(value)
    }
  }

  getValue (varname: string): Value | undefined {
    return this.values.find(v => {
      return v.var == varname
    })
  }

  get dimension (): Value | undefined {
    return this.getValue('dimension')
  }

  get implantation (): Value | undefined {
    return this.getValue('implantation')
  }

  get contour (): Value | undefined {
    return this.getValue('contour')
  }

  get motorisation (): Value | undefined {
    return this.getValue('motorisation')
  }

  get tablier (): Value | undefined {
    return this.getValue('tablier')
  }

  get teinte_cadre (): Value | undefined {
    return this.getValue('teinte_cadre')
  }

  get teinte_fenetre (): Value | undefined {
    return this.getValue('teinte_fenetre')
  }

  get teinte_fond (): Value | undefined {
    return this.getValue('teinte_fond')
  }

  get teinte_tablier (): Value | undefined {
    return this.getValue('teinte_tablier')
  }

  get base (): Value | undefined {
    return this.getValue('base')
  }

  public asObject (): object {
    return Object.assign({
      'implantation': this.getValue('implantation'),
      'contour': this.getValue('contour'),
      'dimension': this.getValue('dimension'),
      'domotique': this.getValue('domotique'),
      'domotique2': this.getValue('domotique2'),
      'domotique3': this.getValue('domotique3'),
      'domotique4': this.getValue('domotique4'),
      'largeur': this.getValue('largeur'),
      'tablier': this.getValue('tablier'),
      'teinte_toile': this.getValue('teinte_toile'),
      'teinte_cadre': this.getValue('teinte_cadre'),
      'motorisation': this.getValue('motorisation') ?? { code: 'undefined' },
      'implantation_zip': this.getValue('implantation_zip'),
      'implantation_vr': this.getValue('implantation_vr') ?? { code: 'undefined' },
      'implantation_vb': this.getValue('implantation_vb')
    }, this)
  }
}
