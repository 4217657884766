import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Configurateur from '@/components/Configurateur.vue'
import Panier from '@/components/Panier.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [

  {
    path: '/configurateur/:configIndex/:tranche/:code/:locale/:cv',
    component: Configurateur,
    name: 'config_code', props: true
  },
  {
    path: '/configurateur/:configIndex/:tranche/:locale/:cv',
    component: Configurateur,
    name: 'config', props: true
  },
  {
    path: '/panier/:code/:locale',
    component: Panier,
    name: 'panier_code', props: true
  },
  {
    path: '/panier/:locale',
    component: Panier,
    name: 'panier', props: true
  },
  {
    path: '*',
    redirect: '/configurateur/0/0/fr_FR/0'
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
