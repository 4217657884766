

import { Component, Prop, Vue } from 'vue-property-decorator'
import Recap from './Common/Recap.vue'
import Steps from './Common/Steps.vue'
import { namespace } from 'vuex-class'
import Icon from 'vue-awesome'
import { Projet } from '@/models/Projet'
import ModalSave from '@/components/Common/ModalSave.vue'
import { ConfigPayload } from '@/store/configurateur.module'
import { getCookie } from '@/util/CookieService'

const ConfigurateurModule = namespace('configurateur')

@Component({
  components: {
    ModalSave,
    Steps,
    Recap,
    Icon
  }
})
export default class Configurateur extends Vue {
  private msg!: string
  @Prop() public configIndex: number | undefined
  @Prop() public locale: string | undefined
  private showVolet = false
  private showModalSave = false
  private showAddVolet = false
  private showKey = false
  private title: string | null = null
  @ConfigurateurModule.Getter public configs!: Configurateur
  @ConfigurateurModule.Getter public projet!: Projet
  @ConfigurateurModule.Getter public isLoad: boolean | undefined

  mounted () {
    if (this.locale && this.configIndex !== undefined) {
      this.$i18n.locale = this.locale
      this.$store.commit('configurateur/setLocale', this.locale)
      const payload = new ConfigPayload()
      payload.locale = this.locale
      payload.noConfig = this.configIndex
      let code = getCookie('solar_conf_code', "")
      if (code) payload.projetCode = code

        this.$store.dispatch('configurateur/fetchConfig', payload)//
        // .then(() => {
          //if (this.configIndex) this.$store.commit('configurateur/setConfigIndex', this.configIndex)
          //console.log("Loaded")
          // this.$store.dispatch('configurateur/loadData')
          /*.then(() => {
          this.$store.commit('configurateur/setLoaded')
        })*/
          //console.log("Fetched, load Data")
        //})
    }
  }

  save (email: string) {
    this.projet.email = email
    this.$store.dispatch('configurateur/save')
    this.showModalSave = false
  }

  choiceVolet () {
    this.$store.commit('setCurrentVolet', this.showKey)
    this.removeOverlay()
  }

  deleteVolet () {
    //this.$store.commit('configurateur/deleteVolet', this.volet)
    this.removeOverlay()
  }

  removeOverlay () {
    this.showModalSave = false
    this.showVolet = false
    this.showAddVolet = false
    this.showKey = false
  }

  dupliquerVolet () {
    this.$store.commit('configurateur/dupVolet')
    this.removeOverlay()
  }

  createVolet (index: number) {
    this.$store.commit('configurateur/addVolet', index)
    this.removeOverlay()
  }

  openVolet (evt: any) {
    //this.title = this.$t('volet.voir') + ' 0' + (evt.key + 1)
    this.showVolet = evt.target
    this.showKey = evt.key
  }

  addVolet () {
    this.title = 'Ajouter un volet'
    this.showAddVolet = true
  }

  openPopupSave () {
    this.showModalSave = true
  }

  closePopupSave () {
    this.showModalSave = false
  }

}

