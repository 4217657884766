export function setCookie(name: string, value: string, exdays: number): void {
  const d = new Date();
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
  const expires = "expires=" + d.toUTCString();
  document.cookie = name + "=" + value + ";" + expires + ";path=/";
}

export function getCookie(name: string, defaultValue: string|null = null): string|null {
  const cookies = document.cookie.split(';');

  for (const cookie of cookies) {
    if (cookie.trimStart().indexOf(name + "=") == 0) {
      return cookie.trimStart().substring(name.length + 1, cookie.length);
    }
  }

  return defaultValue;
}
