

import { Component, Vue } from 'vue-property-decorator'
import Icon from 'vue-awesome'

@Component({
  components: {
    Icon
  }
})
export default class ModalSave extends Vue {

  public email = ""

  valideEmail (email: string) {
    const re = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
    return re.test(email)
  }
  send() {
    this.$emit("save",this.email)
  }

  close() {
    this.$emit('close')
  }
}
