
export class Widget {
  id = "";
  base = false;
  choice: number | null = null;
  class: string | null = "";
  subclass: string | null ="";
  mainOption = false;
  option: any | null = [];
  option_intro: any | null = [];
  vars: string[] | null = [];
}
