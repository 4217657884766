import { Volet } from '@/models/Volet'
import { ConfigItem } from '@/models/ConfigItem'
import { Value } from '@/models/Value'
import { ConfigChoice } from '@/models/ConfigChoice'
import {Type} from "class-transformer";
import { Tranche } from '@/models/Tranche'

export class Projet {
  @Type(() => Volet)
  public volets: Volet[] = []

  public currentVolet: number | null = null

  @Type(() => ConfigItem)
  public globalItems: ConfigItem[] = []

  @Type(() => Value)
  public globalValues: Value[] = []
  public totalOption: number = 0
  email: string = ""
  code: string = ""

  public getVolet (): Volet {
    if (this.currentVolet) {
      return this.volets[this.currentVolet]
    } else return new Volet()
  }

  public isActive (volet: Volet, configItem: ConfigItem, choice: ConfigChoice): string {
    if (this.containValue(volet, configItem, choice)) {
      return 'actif'
    }
    return ''
  }

  public containValue (volet: Volet, configItem: ConfigItem, choice: ConfigChoice): boolean {
    if (configItem.global) {
      return !!this.globalValues.find(v => {
        return v.var == choice.var && v.code == choice.code
      })
    } else {
      return volet.containValue(configItem, choice)
    }
  }

  getValue (choice: ConfigChoice): Value | undefined {
    const value = this.globalValues.find(v => {
      return v.var == choice.var && v.code == choice.code
    })
    return value
  }

  getQte (choice: ConfigChoice): number {
    const value = this.getValue(choice)
    if (value) {
      return value.qte
    }
    return 0
  }

  incQte (choice: ConfigChoice, inc: number) {
    const value = this.getValue(choice)
    if (value) {
      value.qte+= inc
    }
  }

  clickItemQte (choice: ConfigChoice) {
    const v = this.getValue(choice)
    if (v) {
      this.globalValues = this.globalValues.filter(obj => {
        return obj !== v
      })
      return
    }
    const value = new Value()
    value.var = choice.var
    value.libelle = choice.libelle
    value.data = choice.data
    value.code = choice.code
    value.label_step = ''
    value.label_type = ''
    value.choice = choice
    value.qte = 1
    this.setValue(value)
  }

  setValue (value: Value) {
    const newList = this.globalValues.filter(v => {
      return v.var != value.var
    })
    newList.push(value)
    this.globalValues = newList
  }
  getTotalQte (): number {
    let qte = 0
    for (const volet of this.volets) {
      qte += volet.qte
    }
    return qte
  }

  getPrixTotal (): number {

    let total = 0
    for (const volet of this.volets) {
      total += volet.qte * volet.prixU
    }
    total += this.totalOption
    return total
  }

  addVolet (volet: Volet) {
    this.volets.push(volet)
    this.currentVolet= this.volets.length-1
  }

  delVolet (volet: Volet) {
    this.volets = this.volets.filter(obj => {
      return obj !== volet
    })
  }

  calcPrix (tranche: Tranche | undefined) {
    this.totalOption = 0
    for (const valueidx in this.globalValues) {
      const choice = this.globalValues[valueidx].choice
      if (choice && tranche) {
        const prix_option = choice.prixForTranche(tranche)
        this.globalValues[valueidx].prixU = prix_option
        if (prix_option) {
          this.totalOption += Number(prix_option)* this.globalValues[valueidx].qte
        }
      }
    }
  }

  globalItemsAsObject () {
    const obj: any = {}
    for (const value of this.globalValues) {
      obj[value.var] = {"qte": value.qte}
    }
    for (const item of this.globalItems) {
      if (!obj[item.var]) {
        obj[item.var] = { qte: 0 }
      }
    }
    return obj
  }
}
