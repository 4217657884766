import {ConfigChoice} from "@/models/ConfigChoice";
import {Type} from "class-transformer";

export class Value {
  code = ""
  libelle = ""
  public var = ''
  label_step = ''
  label_type = ''
  base = false
  hidden_recap = false
  data = {}

  @Type(() => ConfigChoice)
  choice: ConfigChoice|null = null;
  qte = 0
  prixU = 0
  public hasVisuel() {
    return this.choice && this.choice.visuel;
  }
}
