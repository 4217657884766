import { Volet } from '@/models/Volet'
import {Projet} from "@/models/Projet";
import { Tranche } from '@/models/Tranche'

export class ConfigChoice {
  id = 5000
  condition = null
  conditionDisplay = null
  visuel: string|null = null
  vignette = ''
  color = null
  url = null
  libelle = ''
  var = ''
  prix = 0
  prix2 = 0
  prix3 = 0
  subLibelle = ''
  code = ''
  aide = ''
  description = ''
  description2 = ''
  data = {}
  translations = {}
  locale = null
  cprix = 0
  qte = 1

  isDisplay(volet: Volet): boolean {
    const condition = this.conditionDisplay;

    if(condition) {
      const f = new Function('v', 'return (' + condition + ')');

      try {
        return f(volet.asObject())
      } catch (e) {
        return false;
      }
    }

    return true;
  }
  prixForTranche( tranche: Tranche) {
    // on peux sans doute faire plus simple
    if (tranche.field == "prix") {
      return this.prix
    }
    if (tranche.field == "prix2") {
      return this.prix2
    }
    if (tranche.field == "prix3") {
      return this.prix3
    }
    return 0;
  }

  public getVisuelPath(volet: Volet): string {

    if (!this.visuel) {
      return '';
    }

    if (this.visuel.indexOf('\'') === 0) {
      const f = new Function('v', 'return ' + this.visuel);

      try {
        return f(volet.asObject());
      } catch (e) {
        console.log("Erreur visuel ", this.visuel, volet.asObject())
        // Ne rien faire. L'expression n'a pas pu être évaluée (variables manquantes)
      }
    }

    return this.visuel;
  }

  public isChoosable(volet: Volet, projet: Projet, global: boolean = false): string {
    const condition = this.condition;

    if (condition) {
      const g = projet.globalItemsAsObject()
      const f = new Function('v,g', 'return (' + condition + ')')
      console.log(f.toString())
      try {
        if (global) {
          for (const v of projet.volets) {
            if (f(v.asObject(), g)) {
              return 'choiceable'
            }
          }
          return 'nochoiceable'
        } else {
          return (f(volet.asObject(), g)) ? 'choiceable' : 'nochoiceable'
        }
      } catch (e) {
        console.log('CATCH condition', e, g, condition)
        return 'nochoiceable'
      }
    }
    return 'choiceable'
  }

}
