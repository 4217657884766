import { Tranche } from '@/models/Tranche'
import {Type} from "class-transformer";

export class TranchesConfig {
  @Type(() => Tranche)
  tranches: Tranche[] = []

  constructor (data: any) {
    Object.assign(this, data)
  }
}
