import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { instanceToPlain, plainToInstance } from 'class-transformer'

import axios from 'axios'
import { Configurateur } from '@/models/Configurateur'
import { Projet } from '@/models/Projet'
import { Volet } from '@/models/Volet'
import { ConfigStep } from '@/models/ConfigStep'
import { ConfigElement } from '@/models/ConfigElement'
import { TranchesConfig } from '@/models/TranchesConfig'
import { Tranche } from '@/models/Tranche'
import { getCookie, setCookie } from '@/util/CookieService'
import { ConfigItem } from '@/models/ConfigItem'
import { PrixList } from '@/models/PrixList'
import { Prix } from '@/models/Prix'

export class ConfigPayload {
  locale: string = ''
  noConfig: number = 0
  projetCode?: string = ''
}

@Module({ namespaced: true })
class ConfigurateurStore extends VuexModule {
  public s_configs: Configurateur | null = null
  public s_config: ConfigElement | null = null
  public config_index = 0
  public s_isLoad = false
  public tranchesPrices = new TranchesConfig({
    'tranches': [
      {
        'id': 0,
        'min': 0,
        'max': 1,
        'field': 'prix'
      },
      {
        'id': 1,
        'min': 2,
        'max': 2,
        'field': 'prix2'
      },
      {
        'id': 2,
        'min': 3,
        'max': 99999,
        'field': 'prix3',
        'msg': 'Votre panier n\'atteint pas 3 solutions, les prix ont été recalculés en conséquence.'
      }
    ]
  })
  public currentLocale = 'fr_FR'
  public s_projet: Projet = new Projet()
  public s_volet: Volet | null = null
  private s_step: ConfigStep | null = null
  public s_tranche: Tranche | undefined
  public s_qte = 0
  private s_showWidgetOption: string | null = null
  public s_priceMap = new Map<string, Prix>()

  @Action
  public fetchConfig (payload: ConfigPayload): void {
    this.context.commit('setLocale', payload.locale)
    const lang = this.currentLocale.slice(0, 2)
    axios
      .get<Configurateur>(this.apiUrl + '/json/' + lang).then(res => {
      const configs = plainToInstance(Configurateur, res.data) // to convert user plain object a single user. also supports arrays
      configs.noConfig = payload.noConfig
      this.context.commit('setConfigs', configs)
      if (!payload.projetCode) {
        this.context.commit('initProject')
        this.context.commit('setLoaded')
      } else {
        fetch(this.apiUrl + '/get/' + payload.projetCode, {
          method: 'GET'
        })
          .then(response => response.json())
          .then(data => {
            if (data.status == 'ok') {
              this.s_projet.globalItems = []
              for (const item of data.projet.items) {
                this.s_projet.globalItems.push(plainToInstance(ConfigItem, item))
              }

              this.s_projet.volets = []
              for (const volet of data.projet.volets) {
                this.s_projet.volets.push((plainToInstance(Volet, volet)))
              }

              if (this.s_projet.volets.length > 0) {
                this.context.commit('setVolet', this.s_projet.volets[0])
              }

              this.context.commit(
                'setTranche',
                this.tranchesPrices.tranches.find(t => t.id == data.projet.basetarifaire)
              )
              this.context.commit('setLoaded')
            }
          })
      }
    })
  }

  @Action
  public fetchPrix (): void {
    axios
      .get<PrixList>(this.apiUrl + '/prix').then(res => {
      res.data.prix.forEach(p => {
        this.s_priceMap.set(p.code, new Prix(p))
      }) // to convert user plain object a single user. also supports arrays
    })
  }

  @Action({ rawError: true })
  public save (): void {

    if (this.s_projet) {
      const data = {
        'code': getCookie('solar_conf_code'),
        'email': this.s_projet.email,
        'data': {
          'basetarifaire': this.s_tranche ? this.s_tranche.id : '0',
          'items': instanceToPlain(this.s_projet.globalValues),
          'volets': instanceToPlain(this.s_projet.volets)
        }
      }
      console.log("save", data)
      fetch(this.apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      })
        .then(response => response.json())
        .then(data => {
          if (data.code) {
            setCookie('solar_conf_code', data.code, 360)
            this.context.commit('setCode', data.code)
          }
        })
    }
  }

  @Action({ rawError: true })
  public send (): void {

    if (this.s_projet) {
      axios
        .get(this.apiUrl + '/send/' + this.s_projet.code + '/' + this.currentLocale + '?email=' + this.s_projet.email)
    }
  }

  @Mutation
  public setLoaded (): void {
    this.s_isLoad = true
  }

  @Mutation
  public setCode (code: string): void {
    this.s_projet.code = code
  }

  @Mutation
  public setConfigs (configs: Configurateur): void {
    this.s_configs = configs
    this.config_index = configs.noConfig
    // merge globals items in configs
    for (const config of this.s_configs.configs) {
      config.steps = config.steps.concat(this.s_configs.steps)
    }
    this.s_config = this.s_configs.configs[this.config_index]
    console.log('s_config=', this.s_config)
  }

  @Mutation
  public setConfigIndex (configindex: number): void {
    this.config_index = configindex
    if (this.s_configs) {
      this.s_config = this.s_configs.configs[this.config_index]
    }
  }

  @Mutation
  public addVolet (configindex: number): void {
    this.config_index = configindex
    if (this.s_configs) {
      this.s_config = this.s_configs.configs[this.config_index]
      this.s_volet = this.s_config.newVolet(this.s_projet.volets.length + 1, configindex)
      this.s_projet.addVolet(this.s_volet)
    }
    const top = document.getElementById('global')!.offsetTop // Getting Y of target element
    window.scrollTo(0, top)
  }

  @Mutation
  public dupVolet (): void {
    if (this.s_configs) {
      this.s_config = this.s_configs.configs[this.config_index]
      const newVolet = new Volet(this.s_volet)
      this.s_volet = newVolet
      newVolet.step_index = 0
      newVolet.fini = false
      this.s_projet.addVolet(newVolet)
    }
    const top = document.getElementById('global')!.offsetTop // Getting Y of target element
    window.scrollTo(0, top)
  }

  @Mutation
  public deleteVolet (volet: Volet): void {
    this.s_projet.delVolet(volet)
    this.s_volet = this.s_projet.volets[0]
  }

  @Mutation
  public updatePrixVolet (): void {
    for (const voletidx in this.s_projet.volets) {
      const volet = this.s_projet.volets[voletidx]
      const config = this.s_configs?.configs[volet.noconfig]
      if (config) {
        // Calcul du prix de base
        const codes = []
        for (const field of config.gride_price) {
          const value = volet.getValue(field)
          if (value && value.code) {
            codes.push(value.code)
          }
        }
        const codePrix = config.name + ':' + codes.join('__')
        volet.prixBase = 0
        const prixObj = this.s_priceMap.get(codePrix)
        if (this.s_tranche && prixObj) {
          this.s_projet.volets[voletidx].prixBase = prixObj.prixForTranche(this.s_tranche)
          this.s_projet.volets[voletidx].calcPrix(this.s_tranche)
        } else {
          console.log('Pas de prix pour ' + codePrix)
        }
      } else {
        console.log('no Config')
      }
    }
    this.s_projet.calcPrix(this.s_tranche)
  }

  @Mutation
  public initProject (): void {
    if (this.s_config) {
      this.s_projet = new Projet()
      if (this.s_configs?.steps) {
        for (const gstep of this.s_configs?.steps) {
          for (const gitem of gstep.items) {
            this.s_projet.globalItems.push(gitem)
          }
        }
      }
      this.s_volet = this.s_config.newVolet(1, this.s_configs?.noConfig ?? 0)
      this.s_projet.addVolet(this.s_volet)

      const totalQte = this.s_projet.getTotalQte()
      this.s_tranche = this.tranchesPrices.tranches.find(t => {
        return t.min <= totalQte && t.max >= totalQte
      })
      setCookie('solar_conf_code', '', 360)
    }
  }

  @Mutation
  public setTrancheByQte (): void {
    const totalQte = this.s_projet.getTotalQte()
    this.s_tranche = this.tranchesPrices.tranches.find(t => {
      return t.min <= totalQte && t.max >= totalQte
    })
  }

  @Mutation
  public setTranche (tranche: Tranche): void {
    this.s_tranche = tranche
  }

  @Mutation
  public setVolet (volet: Volet): void {
    this.s_volet = volet
  }

  @Mutation
  public stepInstallation (): void {
    if (this.s_volet) {
      this.s_volet.step_index++
    }
  }

  @Mutation
  public setLocale (locale: string): void {
    this.currentLocale = locale
  }

  @Mutation
  public setShowWidgetOption (option: string): void {
    if (this.s_showWidgetOption == option) {
      this.s_showWidgetOption = null
    } else {
      this.s_showWidgetOption = option
    }
  }

  public setProjet (projet: Projet): void {
    this.s_projet = projet
  }

  get steps (): ConfigStep[] | undefined {
    return this.s_config?.steps
  }

  get isLoad (): boolean {
    return this.s_isLoad
  }

  get locale (): string {
    return this.currentLocale
  }

  get configs (): Configurateur | null {
    return this.s_configs
  }

  get tranche (): Tranche | undefined {
    return this.s_tranche
  }

  get config (): ConfigElement | null {
    return this.s_config
  }

  get volet (): Volet | null {
    return this.s_volet
  }

  get projet (): Projet | null {
    return this.s_projet
  }

  get currentStep (): ConfigStep | null {
    return this.s_step
  }

  get currentLocaleIcon (): string {
    if (this.currentLocale == 'fr_FR') {
      return '/configurateur-dist/static/locales/french.png'
    }
    if (this.currentLocale == 'en_EN') {
      return '/static/locales/english.png'
    }
    if (this.currentLocale == 'de_DE') {
      return '/static/locales/de.png'
    }
    return ''
  }

  get src () {
    return (path: string) => {
      return this.apiUrl + '/assets/' + path
    }
  }

  get showWidgetOption () {
    return this.s_showWidgetOption
  }

  get apiUrl (): string {
    return process.env.VUE_APP_BACKEND_URL || 'https://durableadmin.bubendorff.com/api'
  }

  get prixTtcCode (): string {
    return process.env.VUE_APP_PRIX_TTC_CODE || 'DURABLE'
  }
}

export default ConfigurateurStore
