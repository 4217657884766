

import { Component, Prop, Vue } from 'vue-property-decorator'
import { ConfigStep } from '@/models/ConfigStep'
import { ConfigItem } from '@/models/ConfigItem'
import Icon from 'vue-awesome'
import { Configurateur } from '@/models/Configurateur'
import { Volet } from '@/models/Volet'
import { namespace } from 'vuex-class'
import { ConfigChoice } from '@/models/ConfigChoice'
import { Projet } from '@/models/Projet'
import { Value } from '@/models/Value'
import { Tranche } from '@/models/Tranche'
import { ConfigElement } from '@/models/ConfigElement'

const ConfigurateurModule = namespace('configurateur')

@Component({
  components: {
    Icon
  }
})
export default class Widget extends Vue {

  aide = false
  aideItem: string = ''
  aideItemCode: string = ''
  ok = false

  @Prop({ required: true }) public choices!: ConfigChoice[]
  @Prop({ required: true }) public configItem!: ConfigItem
  @Prop({ required: true }) public step!: ConfigStep
  @Prop({ required: true }) public indexStep!: number
  @Prop({ required: true }) public indexWidget!: number
  @ConfigurateurModule.Getter public configs!: Configurateur
  @ConfigurateurModule.Getter public prixTtcCode!: string
  @ConfigurateurModule.Getter public volet!: Volet
  @ConfigurateurModule.Getter public projet!: Projet
  @ConfigurateurModule.Getter public src!: string
  @ConfigurateurModule.Getter public tranche!: Tranche
  @ConfigurateurModule.Getter public showWidgetOption!: string | null

  isMobile () {
    return (window.innerWidth <= 768)
  }

  toggleShowWidgetOption (option: string) {
    this.$store.commit('configurateur/setShowWidgetOption', option)
  }

  setItemQte (item: ConfigItem, qte: number) {
    this.$store.commit('setItemQte', {
      qte,
      item
    })
  }
  clickGlobalItemQte(choice: ConfigChoice) {
    if (choice.isChoosable(this.volet, this.projet) == 'choiceable') {
      this.projet.clickItemQte(choice)
      this.$store.commit('configurateur/updatePrixVolet')
      this.$store.dispatch('configurateur/save')

    }
  }
  incGlobalItemQte(choice: ConfigChoice, inc: number) {
    this.projet.incQte(choice, inc)
    this.$store.commit('configurateur/updatePrixVolet')
    this.$store.dispatch('configurateur/save')
  }
  getItemsByVariablesInVolet (volet: Volet, vars: string[]) {
    return this.$store.state.configs.getItemsByVariablesInVolet(volet, vars)
  }

  incQte (inc: number) {
    this.volet.qte += inc
    this.$store.commit('configurateur/setTrancheByQte')
    this.$store.commit('configurateur/updatePrixVolet')
    this.$store.dispatch('configurateur/save')
  }

  classOpenAide (item: ConfigItem) {
    if (this.aideItemCode == item.code) {
      return 'open-aide'
    }
  }

  autoSelectChoice () {
    const value = this.volet.getValue(this.configItem.var)

    if (this.configItem.widget && this.configItem.widget.id == 'autoset' && value === undefined) {
      if (this.choices.length > 0) {
        this.selectChoice(this.choices[0])
      }
    }
  }

  selectChoice (choice: ConfigChoice) {
    /*
    GESTION DU RESET
    */

    if (choice.isChoosable(this.volet, this.projet, this.configItem.global) != 'choiceable') return
    if (this.step.reset) {
      for (const field of this.step.reset) {
        this.volet.resetVariable(field)
      }
    }
    let value = new Value()
    value.var = this.configItem.var
    value.libelle = choice.libelle
    value.data = choice.data
    value.code = choice.code
    value.label_step = this.step.label
    value.label_type = this.configItem.label
    value.base = this.configItem.widget?.base ? this.configItem.widget.base : false
    value.hidden_recap = this.configItem.hiddenRecap
    value.choice = choice
    /*
    ??? c'est quoi ce truc ?
    ON DIRAIT UNE COPIE DE VALEUR D'un item a l'autre. ??? Pourquoi faire ?
    if (value.data && value.data.tvar && value.data.svar) {
      let src = value.data.svar.split('.')
      value.data[value.data.tvar] = this.volet[src[0]].data[src[1]]
    }
    */

    if (this.configItem.global) {
      this.projet.setValue(value)
    } else {
      this.volet.resetValue(this.configItem)
      this.volet.setValue(value)
    }
    this.$store.commit('configurateur/setTrancheByQte')
    this.$store.commit('configurateur/updatePrixVolet')
    this.$store.dispatch('configurateur/save')

  }

  mounted () {
    this.autoSelectChoice()
  }

  toggleAideOpen (choice: ConfigChoice) {
    if (this.aideItemCode == choice.code) {
      this.aideItem = ''
      this.aideItemCode = ''
    } else {
      this.aideItem = choice.aide
      this.aideItemCode = choice.code
    }
  }

  clickok () {
    this.volet.fini = true
    this.ok = true
  }

  toggleAide () {
    this.aide = !this.aide
  }

  finir () {
    this.volet.fini = true
    this.$store.commit('configurateur/stepInstallation')
  }

  addVolet (index: number) {
    this.volet.fini = true
    this.$store.commit('configurateur/addVolet', index)
  }

  addVoletWithImp (index: number) {
    this.volet.fini = true
    this.$store.commit('configurateur/dupVolet')
    this.volet.step_index=2
  }

}
