import Vue from 'vue'

export const capitalizeFilter = Vue.filter(
  'capitalize', function (value: string) {
    if (value) {
      return value.charAt(0).toUpperCase() + value.slice(1)
    }
  })
export const capitalizeAllFilter = Vue.filter(
  'capitalizeall', function (value: string) {
    if (value) {
      return value.toUpperCase()
    }
  })

export const priceFilter = Vue.filter('price', function (value: string) {
  if (value) {
    value = Number(value).toFixed(2)
    const sep = ' '
    const l = value.length
    return value.substring(0, l % 3) + value.substring(l % 3).replace(/(\d{3})/, sep + '$1') + ' € *'
  } else {
    return '0 € *'
  }
})
