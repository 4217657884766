

import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { Configurateur } from '@/models/Configurateur'
import { Volet } from '@/models/Volet'
import { ConfigElement } from '@/models/ConfigElement'
import { Projet } from '@/models/Projet'
import Icon from 'vue-awesome'
import { Tranche } from '@/models/Tranche'
import VisuelVolet from '@/components/Common/VisuelVolet.vue'

const ConfigurateurModule = namespace('configurateur')

@Component({
  components: {
    VisuelVolet,
    Icon
  }
})
export default class Recap extends Vue {
  editName = false
  editNameValue: string | null = null
  detailPrice = false

  public dispoExtTradi = {
    type: Boolean,
    default: false
  }
  public dispoExtMono = {
    type: Boolean,
    default: false
  }

  @ConfigurateurModule.Getter public isLoad!: boolean
  @ConfigurateurModule.Getter public locale!: string
  @ConfigurateurModule.Getter public configs!: Configurateur
  @ConfigurateurModule.Getter public prixTtcCode!: string
  @ConfigurateurModule.Getter public config!: ConfigElement
  @ConfigurateurModule.Getter public volet!: Volet
  @ConfigurateurModule.Getter public projet!: Projet
  @ConfigurateurModule.Getter public tranche!: Tranche
  @ConfigurateurModule.Getter public src!: string

  /*
  currentStep () {
    const noStep = (this.volet.step) ? this.volet.step : 0
    return this.config.steps[noStep]
  }
  */
  goLastStep () {
    const index = this.config.steps.length - 1
    this.$store.commit('configurateur/setStep', index)
  }

  printpanier () {
    this.$router.push({ name: 'panier' }).then(() => {
      print()
    })
  }

  reset () {
    this.$store.commit('configurateur/initProject')
  }

  editNameVolet () {
    this.editNameValue = this.volet.name
    this.editName = true
  }

  saveNameVolet () {
    this.$store.commit('setName', this.editNameValue)
    this.editName = false
  }

  editVolet (volet: Volet) {
    volet.step_index = 0
    this.$store.commit('configurateur/setVolet', volet)
  }

  addVolet () {
    this.$emit('add-volet')
  }

  save () {
    this.$emit('open-popup')
  }

  toggleDetailPrice () {
    this.detailPrice = !this.detailPrice
  }

}
