

import { Component, Prop, Vue } from 'vue-property-decorator'
import Icon from 'vue-awesome'
import { Volet } from '@/models/Volet'
import { namespace } from 'vuex-class'
const ConfigurateurModule = namespace('configurateur');

@Component({
  components: {
    Icon
  }
})
export default class VisuelVolet extends Vue {
  @Prop({ required: true }) public volet: Volet | undefined

  @ConfigurateurModule.Getter public src!: string;

}

