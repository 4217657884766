import { ConfigItem } from '@/models/ConfigItem'
import {Type} from "class-transformer";

export class ConfigStep {
  label: string=''
  labelHeader: string = ''
  labelRecap: string | null = null
  labelNext: string | null = null
  icon: string = ""
  reset: string[] | null = []
  hiddenRecap = false
  final = false
  translations: string[] = []
  locale = "fr"
  global = false
  @Type(() => ConfigItem)
  items: ConfigItem[] = [];

  last_only = false
}
